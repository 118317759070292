import React, { useContext, useEffect, useState } from "react";
import runningInBrowser from "../util/runningInBrowser";
import getLogger from "../util/getLogger";
import UnsupportedBrowser from "../components/UnsupportedBrowser";
import getBrowserInfo from "../util/getBrowserInfo";
import FeatureFlagContext from "./FeatureFlagContext";

const { isSupported: isSupportedByTwilio } = require("twilio-video");

const logger = getLogger("BrowserContext");

const BrowserContext = React.createContext();

export const BrowserContextProvider = (props) => {
  const { flags, flagsReady } = useContext(FeatureFlagContext);
  const [info, setInfo] = useState({});
  const [browserSupported, setBrowserSupported] = useState(null);

  useEffect(() => {
    const info = getBrowserInfo();
    setInfo(info);
  }, []);

  useEffect(() => {
    if (runningInBrowser()) {
      const browserDetermined = Object.keys(info).length > 0;

      if (window.Cypress) {
        setBrowserSupported(true);
      } else if (browserDetermined && flagsReady) {
        logger.info("Determined browser", info);

        if (flags["skip-browser-compatibility-check"]) {
          logger.info("Skipping browser check");
          if (!isSupportedByTwilio) {
            logger.warn("FYI: This browser is not supported by Twilio");
          } else {
            logger.info("FYI: This browser is supported by Twilio");
          }
          setBrowserSupported(true);
        } else if (
          info.browser &&
          info.browser.name === "Chrome" &&
          info.platform &&
          info.platform.type === "desktop" &&
          isSupportedByTwilio
        ) {
          setBrowserSupported(true);
        } else {
          setBrowserSupported(false);
        }
      }
    }
  }, [info, flagsReady, flags]);

  if (browserSupported === null) {
    logger.debug("not rendering any children yet");
    return null;
  }

  logger.debug("ready to render children");

  logger.debug("browserSupported", browserSupported);

  if (browserSupported === false) {
    return <UnsupportedBrowser />;
  }

  return (
    <BrowserContext.Provider
      value={{
        browserName: info && info.browser && info.browser.name,
        osName: info && info.os && info.os.name,
      }}
    >
      {props.children}
    </BrowserContext.Provider>
  );
};

export default BrowserContext;
