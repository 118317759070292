/* eslint-disable no-console */

import log from "loglevel";
import runningInBrowser from "./runningInBrowser";
import getTimeStamp from "./getTimeStamp";

log.setDefaultLevel("DEBUG");

if (runningInBrowser()) {
  window.log = log;
}

export default (name) => {
  const logger = log.getLogger(name);

  const wrapper = (fn, level) => (...args) => {
    const timeStamp = getTimeStamp();
    const levelInCaps = level.toUpperCase();

    console[level](`[${timeStamp}] ${levelInCaps}: ${name}: `, ...args);
  };

  ["trace", "debug", "info", "warn", "error"].forEach((level) => {
    logger[level] = wrapper(logger[level], level);
  });

  logger.logRender = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions
  ) => {
    logger.debug(
      `Render ${id}, phase: ${phase}, actualDuration: ${actualDuration}, baseDuration: ${baseDuration}, startTime: ${startTime}, commitTime: ${commitTime}, interactions: `,
      interactions
    );
  };

  return logger;
};

// We'll come back to using the logger properly when we can figure out how to get it to appear in LogRocket
//
// export default (name) => {
//   const logger = log.getLogger(name);
//
//   const wrapper = (fn, level) => (message) => {
//     const timeStamp = getTimeStamp();
//     const levelInCaps = level.toUpperCase();
//
//     if (typeof message === "string") {
//       fn(`[${timeStamp}] ${levelInCaps}: ${name}: ${message}`);
//     } else {
//       // so we can preserve displaying objects
//       fn(message);
//     }
//   };
//
//   ["trace", "debug", "info", "warn", "error"].forEach((level) => {
//     logger[level] = wrapper(logger[level], level);
//   });
//
//   return logger;
// };
